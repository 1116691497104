<template>
  <!--begin::Customer Listing-->
  <div class="equipment-template">
    <!-- {{ dataLoading }}  equipment instead of routeName-->
    <PageHeaderCount
      :moduleType="routeName"
      :dataLoading="dataLoading"
      :statusList="statusList"
      allkey="all_equipments"
      countkey="equipment_status_count"
    ></PageHeaderCount>

    <!--  <v-col
        v-if="alphabets.length"
        md="12"
        class="alphabets-filter d-flex justify-center"
      >
        <div class="alphabets-scrollable" v-if="false">
          <v-btn
            depressed
            v-on:click="getLineItem(null, 'alphabet')"
            :class="{
              'cyan--text': null == alphabet,
              'cyan white--text': null != alphabet,
            }"
            class="custom-bold-button"
            small
            >All</v-btn
          >
          <v-btn
            depressed
            v-for="(row, index) in alphabets"
            v-on:click="getLineItem(row, 'alphabet')"
            :class="{
              'cyan--text': row == alphabet,
              'cyan white--text': row != alphabet,
            }"
            class="custom-bold-button"
            small
            :key="index"
            >{{ row }}
            <v-badge
              bordered
              color="error"
              class="custom-margin"
              overlap
              dot
              offset-x="10"
              offset-y="10"
            >
            </v-badge>
          </v-btn>
        </div>
      </v-col> -->
    <ListingTemplate
      :customClass="'equipment-listing'"
      v-if="getPermission('equipment:view')"
    >
      <template v-slot:body>
        <ListingHeader>
          <template v-slot:content>
            <template v-if="selectedRows.length > 0">
              <v-flex class="pt-0 mxw-200 my-auto">
                <h1 class="form-title d-flex margin-auto selected-rows-text">
                  {{ totalSelected }} Selected
                </h1>
              </v-flex>
              <v-flex class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('equipment:update')">
                  <v-menu
                    content-class="custom-menu-list"
                    bottom
                    offset-y
                    transition="slide-y-transition"
                    :close-on-click="true"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="dataLoading"
                        class="mx-2 custom-grey-border custom-bold-button"
                        color="cyan white--text"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark left>mdi-chevron-down</v-icon>Bulk Action
                      </v-btn>
                    </template>
                    <v-list>
                      <template v-for="(more, index) in equipmentMoreAction">
                        <v-list-item
                          link
                          v-on:click="updateMoreAction(more.action)"
                          :key="index"
                        >
                          <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                            <v-icon class="icon-default-blue">{{
                              more.icon
                            }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            class="font-weight-500 font-size-14"
                            >{{ more.title }}</v-list-item-title
                          >
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </template>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="red lighten-1 white--text"
                  v-on:click="clearSelections"
                >
                  <v-icon dark left>mdi-close</v-icon>Clear Selections
                </v-btn>
              </v-flex>
            </template>
            <template v-else>
              <!-- <v-flex class="pt-0 mxw-200">
                  <h1 class="form-title d-flex margin-auto">
                    <v-select :disabled="dataLoading" :items="statusList" v-model="status" hide-details item-color="cyan"
                      class="pt-0 mt-0 listing-status main-listing-status-filter" item-text="text" item-value="value">
                      <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title v-text="item.text"></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-chip :color="item.color" :text-color="item.textcolor">
                            <template v-if="item.value == 'all'">{{
                              item.all_warranty
                            }}</template>
                            <template v-else>{{
                              item.warranty_status_count
                            }}</template>
                          </v-chip>
                        </v-list-item-action>
                      </template>
                    </v-select>
                  </h1>
                </v-flex> -->
              <v-flex class="pt-0 mxw-200">
                <h1 class="form-title d-flex margin-auto">
                  <v-select
                    :disabled="dataLoading"
                    :items="statusList"
                    v-model="status"
                    hide-details
                    item-color="cyan"
                    class="pt-0 mt-0 listing-status main-listing-status-filter"
                    item-text="text"
                    item-value="value"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip
                          :color="item.color"
                          :text-color="item.textcolor"
                        >
                          <template v-if="item.value == 'all'">{{
                            item.all_equipments
                          }}</template>
                          <template v-else>{{
                            item.equipment_status_count
                          }}</template>
                        </v-chip>
                      </v-list-item-action>
                    </template>
                  </v-select>
                </h1>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex
                class="pt-0 justify-flex-end d-flex margin-auto all-project-btn-right"
              >
                <v-autocomplete
                  v-if="false"
                  class="ml-1"
                  hide-details
                  v-model.trim="filter_customer"
                  clearable
                  :items="customerList"
                  dense
                  flat
                  filled
                  placeholder="Customer"
                  item-color="cyan"
                  color="cyan"
                  solo
                  item-text="display_name"
                  item-value="id"
                  v-on:change="getRows()"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title
                        v-html="'No Customer(s) Found.'"
                      ></v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-avatar>
                      <v-img
                        :lazy-src="$defaultProfileImage"
                        :src="$assetAPIURL(item.profile_logo)"
                        aspect-ratio="1"
                        class="margin-auto"
                        transition="fade-transition"
                      ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-capitalize font-weight-500 font-size-16"
                        >{{ item.display_name }}</v-list-item-title
                      >
                      <v-list-item-title
                        class="text-capitalize font-weight-500 font-size-16"
                        >{{ item.company_name }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action
                      v-if="item.default_person"
                      class="align-self-center"
                    >
                      <v-list-item-subtitle
                        class="text-lowercase pb-2 font-weight-500 font-size-14"
                        >{{
                          item.default_person.primary_email
                        }}</v-list-item-subtitle
                      >
                      <v-list-item-subtitle
                        class="text-lowercase font-weight-500 font-size-14"
                        >{{
                          item.default_person.primary_phone
                        }}</v-list-item-subtitle
                      >
                    </v-list-item-action>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                  v-if="false"
                  class="ml-1"
                  hide-details
                  clearable
                  v-model.trim="filter_property"
                  :items="propertyList"
                  dense
                  flat
                  filled
                  placeholder="Site Location"
                  item-color="cyan"
                  color="cyan"
                  solo
                  item-text="barcode"
                  item-value="id"
                  v-on:change="getRows()"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title
                        v-html="'No Site Location(s) Found.'"
                      ></v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-icon class="mr-3">
                      <v-icon>mdi-home-map-marker</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="text-ellipsis max-width-400px">
                      <v-list-item-title
                        class="text-capitalize font-weight-500 font-size-16"
                        >{{ item.barcode }} (
                        <template v-if="item.unit_no">
                          {{ item.unit_no }},</template
                        >
                        <template v-if="item.street_1">
                          {{ item.street_1 }},</template
                        >
                        <template v-if="item.street_2">
                          {{ item.street_2 }},</template
                        >
                        <template v-if="item.zip_code">{{
                          item.street_2
                        }}</template
                        >)
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                  class="ml-1"
                  hide-details
                  v-model.trim="warranty_status"
                  :items="getWarrantyStatusList"
                  clearable
                  style="max-width: 250px !important"
                  dense
                  flat
                  filled
                  placeholder="Warranty Status"
                  item-color="cyan"
                  color="cyan"
                  solo
                  :disabled="dataLoading"
                  :loading="dataLoading"
                  v-on:change="getRows()"
                  item-value="value"
                  item-text="text"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title
                        v-html="'No Status(s) Found.'"
                      ></v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <v-list-item-action class="ma-0">
                      <v-chip
                        style="height: 10px; width: 10px; padding: 0"
                        :color="item.color"
                      >
                      </v-chip>
                    </v-list-item-action>
                    <v-list-item-content class="py-0">
                      <v-list-item-title
                        class="text-capitalize font-weight-500 font-size-16"
                      >
                        <template v-if="item.value == 'all'"
                          >{{ item.text }}
                        </template>
                        <template v-else>{{ item.text }} </template>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-action class="mr-0">
                      <v-chip
                        style="height: 16px; width: 16px; padding: 0"
                        :color="item.color"
                      >
                      </v-chip>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-capitalize font-weight-500 font-size-16"
                        >{{ item.text }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                  v-if="false"
                  class="ml-1"
                  hide-details
                  v-model.trim="filter_type"
                  :items="equipmentType"
                  clearable
                  style="max-width: 250px !important"
                  dense
                  flat
                  filled
                  placeholder="Equipment type"
                  item-color="cyan"
                  color="cyan"
                  solo
                  :disabled="dataLoading"
                  :loading="dataLoading"
                  v-on:change="getRows()"
                  item-value="value"
                  item-text="text"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title
                        v-html="'No Status(s) Found.'"
                      ></v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <v-list-item-action class="ma-0">
                      <v-chip
                        style="height: 10px; width: 10px; padding: 0"
                        :color="item.color"
                      >
                      </v-chip>
                    </v-list-item-action>
                    <v-list-item-content class="py-0">
                      <v-list-item-title
                        class="text-capitalize font-weight-500 font-size-16"
                      >
                        <template v-if="item.value == 'all'"
                          >{{ item.text }}
                        </template>
                        <template v-else>{{ item.text }} </template>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-action class="mr-0">
                      <v-chip
                        style="height: 16px; width: 16px; padding: 0"
                        :color="item.color"
                      >
                      </v-chip>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-capitalize font-weight-500 font-size-16"
                        >{{ item.text }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
                <template v-if="getPermission('equipment:create')">
                  <v-btn
                    depressed
                    :disabled="dataLoading"
                    :to="getRoute()"
                    class="mx-2 custom-bold-button custom-btn-color white--text"
                  >
                    <v-icon dark left>mdi-plus</v-icon> Create
                  </v-btn>
                </template>
                <v-btn
                  depressed
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button white--text"
                  color="cyan"
                  v-on:click="searchDialog = true"
                >
                  <v-icon dark>mdi-filter</v-icon>
                </v-btn>
                <!-- <v-btn
                    v-if="!isEngineerChannel()"
                    :disabled="dataLoading"
                    class="mx-2 custom-grey-border custom-bold-button btn-tab-hide"
                    color="cyan white--text"
                    v-on:click="
                      $router.push(
                        getDefaultRoute('profile.setting', {
                          query: {
                            tab: 'customer',
                          },
                        })
                      )
                    "
                  >
                    <v-icon dark>mdi-cog</v-icon>
                  </v-btn> -->
                <!-- <v-menu content-class="custom-menu-list" offset-y left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="dataLoading"
                        class="mx-2 custom-bold-button"
                        color="cyan white--text"
                        v-on:click="exportCustomer"
                      >
                        <v-icon dark left>mdi-database-export</v-icon> Export
                      </v-btn>
                      <v-btn
                        :disabled="dataLoading || exportLoading"
                        class="mx-2 custom-bold-button"
                        color="cyan white--text"
                        v-bind="attrs"
                        v-on="on"
                        :loading="exportLoading"
                      >
                        <v-icon dark>mdi-menu</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <template v-for="(item, index) in moreActions">
                        <v-list-item
                          :key="index"
                          link
                          v-on:click="moreAction(item.action, item.sort)"
                        >
                          <v-list-item-title>
                            <v-icon v-if="item.icon" left color="cyan">{{
                              item.icon
                            }}</v-icon>
                            {{ item.title }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu> -->

                <v-menu
                  content-class="custom-menu-list"
                  offset-y
                  left
                  v-if="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <!-- <v-btn
                        :disabled="dataLoading"
                        class="mx-2 custom-bold-button"
                        color="cyan white--text"
                        v-on:click="exportCustomer"
                      >
                        <v-icon dark left>mdi-database-export</v-icon> Export
                      </v-btn> -->
                    <v-btn
                      depressed
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button"
                      v-on:click="importDialog = true"
                      color="cyan white--text"
                    >
                      <v-icon dark left>mdi-database-import</v-icon> Import
                    </v-btn>
                  </template>
                  <v-list>
                    <template v-for="(item, index) in moreActions">
                      <v-list-item
                        :key="index"
                        link
                        v-on:click="moreAction(item.action, item.sort)"
                      >
                        <v-list-item-title>
                          <v-icon v-if="item.icon" left color="cyan">{{
                            item.icon
                          }}</v-icon>
                          {{ item.title }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>

                <v-menu
                  max-height="400"
                  max-width="250"
                  offset-y
                  left
                  :close-on-content-click="false"
                  content-class="white-background"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button btn-tab-hide"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <draggable
                    tag="ul"
                    v-model="defaultColDefs"
                    v-on:change="updateVisible()"
                    class="draggable-group"
                    handle=".draggable-drag-icon"
                  >
                    <template v-for="cols in defaultColDefs">
                      <li
                        class="draggable-group-item"
                        :key="cols.field"
                        v-if="!cols.checkbox || Number(cols.checkbox) == 0"
                      >
                        <v-checkbox
                          dense
                          v-model="defaultColShow"
                          v-bind:value="cols.field"
                          :label="cols.headerName"
                          :disabled="Number(cols.fixed) || dataLoading"
                          color="cyan"
                          hide-details
                          class="mt-0 mb-0"
                          v-on:change="updateVisible()"
                        ></v-checkbox>
                        <v-icon class="draggable-drag-icon" right color="cyan"
                          >mdi-drag</v-icon
                        >
                      </li>
                    </template>
                  </draggable>
                </v-menu>
                <!-- <v-menu v-if="false" offset-y left>
                    <template v-slot:activator="{ on: menu, attrs }">
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on: tooltip }">
                          <v-btn
                            :disabled="dataLoading"
                            class="ml-2 custom-bold-button"
                            color="cyan white--text"
                            v-bind="attrs"
                            v-on="{ ...tooltip, ...menu }"
                          >
                            {{ entity.name }}
                          </v-btn>
                        </template>
                        <span>Entity</span>
                      </v-tooltip>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(item, index) in entityList"
                        :key="index"
                        link
                        v-on:click="updateEntity(item)"
                      >
                        <v-list-item-title class="font-weight-600">{{
                          item.name
                        }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu> -->
                <PageTips v-if="false" module="customer"></PageTips>
              </v-flex>
            </template>
          </template>
        </ListingHeader>
        <ListingSearchCriteria
          v-if="searchEnabled"
          pageModule="Equipment"
          :basicSearchFields="['unique_id', 'name', 'description']"
          :allowedSearchFields="searchableArray"
        ></ListingSearchCriteria>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 90vh; position: relative"
        >
          <!-- <pre>{{defaultColDefs}}</pre> -->
          <ListingTable
            :columnCount="columnLength()"
            :dataLoading="dataLoading"
            :rowData="rowData"
          >
            <template v-slot:thead>
              <thead>
                <tr>
                  <template v-for="cols in customThead">
                    <th
                      v-if="cols.visible"
                      :key="cols.id"
                      class="simple-table-th"
                      :class="{
                        'checkbox-width-limit': cols.checkbox,
                        'pointer-events-none': lodash.isEmpty(rowData),
                      }"
                    >
                      <template
                        v-if="cols.checkbox && Number(cols.checkbox) == 1"
                      >
                        <v-checkbox
                          dense
                          :disabled="dataLoading"
                          v-model="selectedAll"
                          color="cyan"
                          class="hide-margin"
                          hide-details
                        ></v-checkbox>
                      </template>
                      <template v-else>
                        <div
                          v-if="cols.sortable"
                          class="simple-table-text sortable"
                          v-on:click="
                            !dataLoading
                              ? updateSorting({
                                  field: cols.field,
                                  sort: cols.sort,
                                })
                              : 'javascript:void(0)'
                          "
                        >
                          {{ cols.headerName }}
                        </div>
                        <div v-else class="simple-table-text">
                          {{ cols.headerName }}
                        </div>

                        <template v-if="cols.sort">
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'asc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-ascending</v-icon
                          >
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'desc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-descending</v-icon
                          >
                        </template>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody class="custom-border-bottom">
                <template v-if="!lodash.isEmpty(rowData)">
                  <tr
                    v-for="(data, index) in rowData"
                    :key="index"
                    link
                    class="warranty-listing-row"
                    :class="{ 'table-in-active-row': !data.activated }"
                  >
                    <template v-for="cols in customThead">
                      <td
                        v-if="cols.visible"
                        :key="cols.id"
                        :class="{
                          'simple-table-td': !cols.checkbox,
                          'checkbox-width-limit': cols.checkbox,
                        }"
                        v-on:click="
                          Number(cols.checkbox) ==
                          0 /* && cols.field != 'name' */
                            ? detailAsset(data.id)
                            : 'javascript:void(0)'
                        "
                      >
                        <template
                          v-if="cols.checkbox && Number(cols.checkbox) == 1"
                        >
                          <v-checkbox
                            dense
                            v-model="selectedRows"
                            v-bind:value="data.id"
                            color="cyan"
                            class="hide-margin"
                            hide-details
                          ></v-checkbox>
                        </template>
                        <template v-else-if="cols.field == 'image'">
                          <template v-if="data.image">
                            <v-avatar size="50">
                              <v-img
                                class="customer-image"
                                width="50"
                                height="50"
                                contain
                                :lazy-src="$defaultImage"
                                :src="data.image"
                              >
                              </v-img>
                            </v-avatar>
                          </template>
                          <template v-else>
                            <v-img
                              class="customer-image"
                              width="50"
                              height="50"
                              contain
                              :lazy-src="$defaultImage"
                              :src="$defaultImage"
                            >
                            </v-img>
                          </template>
                        </template>

                        <template v-else-if="cols.field == 'equipment_info'">
                          <div class="warranty-listing-product">
                            <template v-if="data">
                              <span class="m-0 custom-nowrap-ellipsis">
                                <div>
                                  <v-chip
                                    :color="
                                      getStatusColorEquip(data.status_value)
                                    "
                                    label
                                    text-color="white"
                                    style="height: 25px"
                                    class="mr-2 mb-2"
                                    v-if="data && data.status_value"
                                  >
                                    {{ data.status_value }}
                                  </v-chip>
                                </div>

                                <v-chip
                                  :color="getStatusColorEquip('Faulty')"
                                  label
                                  text-color=""
                                  outlined
                                  style="height: 25px"
                                  class="mr-2"
                                  v-if="data && data.faulty"
                                >
                                  Faulty
                                </v-chip>
                                <v-chip
                                  :color="getStatusColorEquip('Retired')"
                                  label
                                  text-color=""
                                  outlined
                                  style="height: 25px"
                                  class="mr-2"
                                  v-if="data && data.retired"
                                >
                                  Retired
                                </v-chip>

                                <!-- <template v-if="data && data.status_value"
                                    ><span class="font-weight-700">{{
                                      data.status_value
                                    }}</span></template
                                  >
                                  <template v-else
                                    ><em class="text--secondary"
                                      >No Display Name</em
                                    ></template
                                  > -->
                              </span>
                            </template>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'equipment_no'">
                          <template v-if="data && data.barcode">
                            <div style="text-align: center">
                              <Barcode :barcode="data.barcode"></Barcode>
                            </div>
                            <div style="text-align: center">
                              <showBarcode
                                :barcodeUrl="data.barcode_image"
                                title="Barcode"
                                :barcode-text="data.barcode"
                              />
                            </div>
                          </template>
                        </template>
                        <template v-else-if="cols.field == 'customer'">
                          <div class="warranty-listing-product">
                            <template
                              v-if="data && data.main_type == 'main_customer'"
                            >
                              <p class="m-0 custom-nowrap-ellipsis">
                                <b>Customer #: </b>
                                <template v-if="data && data.customer_barcode"
                                  ><span class="font-weight-700">{{
                                    data.customer_barcode
                                  }}</span></template
                                >
                                <template v-else
                                  ><em class="text--secondary"
                                    >No Display Name</em
                                  ></template
                                >
                              </p>
                              <p class="m-0 custom-nowrap-ellipsis">
                                <b>Display Name: </b>
                                <template
                                  v-if="data && data.customer_display_name"
                                  >{{ data.customer_display_name }}</template
                                >
                                <template v-else
                                  ><em class="text--secondary"
                                    >No Display Name</em
                                  ></template
                                >
                              </p>
                              <p class="m-0 custom-nowrap-ellipsis">
                                <b>Company: </b>
                                <template
                                  v-if="data && data.customer_company_name"
                                  >{{ data.customer_company_name }}</template
                                >
                                <template v-else
                                  ><em class="text--secondary"
                                    >No Company</em
                                  ></template
                                >
                              </p>
                              <p class="m-0 custom-nowrap-ellipsis">
                                <b>Email: </b>
                                <template v-if="data && data.company_email">{{
                                  data.company_email
                                }}</template>
                                <template v-else
                                  ><em class="text--secondary"
                                    >No Email</em
                                  ></template
                                >
                              </p>
                              <p class="m-0 custom-nowrap-ellipsis">
                                <b>Phone: </b>
                                <template
                                  v-if="
                                    data.customer &&
                                    data.customer_company_number
                                  "
                                  >{{ data.customer_company_number }}</template
                                >
                                <template v-else
                                  ><em class="text--secondary"
                                    >No Phone</em
                                  ></template
                                >
                              </p>
                            </template>
                            <template v-else>
                              <v-chip
                                label
                                color="#0d47a1"
                                outlined
                                small
                                class="p-3 mr-5"
                              >
                                Own Asset
                              </v-chip>
                            </template>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'property'">
                          <template v-if="data.main_type == 'main_customer'">
                            {{ getFormattedProperty(data) }}
                          </template>
                          <template v-if="data.main_type == 'main_company'">
                            {{ getFormattedCompany() }}
                          </template>
                        </template>
                        <template v-else-if="cols.field == 'name'">
                          <template v-if="data && data.name">
                            <div class="warranty-listing-product">
                              <p class="m-0 custom-nowrap-ellipsis">
                                <read-more
                                  class="custom-read-more font-weight-600 font-size-18 pb-1 text-capitalize"
                                  more-str="read more"
                                  :text="data.name || '<em>No location</em>'"
                                  link="#"
                                  less-str="read less"
                                  :max-chars="35"
                                >
                                </read-more>
                              </p>
                            </div>
                          </template>
                          <em class="text-muted" v-else> no name</em>
                          <template
                            v-if="data.ticket > 0 && data.visit_barcode"
                          >
                            <Barcode
                              route="visit.detail"
                              :barcode="data.visit_barcode"
                              :id="data.visit_id"
                            ></Barcode>
                            <!--  <v-chip
                                  label
                                  dense
                                  small
                                  color="chip-custom-blue"
                                  outlined
                                  class="font-weight-600 custom-barcode"
                                  >{{ data.visit_barcode }}
                                </v-chip> -->
                            <template v-if="data.visit_status == 4">
                              <v-chip
                                label
                                small
                                text-color="white"
                                color="green"
                                class="mt-2"
                              >
                                Complate</v-chip
                              >
                            </template>
                            <template v-else>
                              <v-chip
                                label
                                small
                                text-color="white"
                                color="red"
                                class="mt-2"
                              >
                                Pending</v-chip
                              >
                            </template>
                          </template>
                        </template>
                        <template v-else-if="cols.field == 'description'">
                          <template v-if="data && data.description">
                            <div class="line-clamp-2" style="max-width: 200px">
                              {{ data.description }}
                            </div>
                          </template>
                          <em v-else class="text-muted"> no description</em>
                        </template>
                        <template v-else-if="cols.field == 'serial_no'">
                          <template v-if="data.serial_no">
                            <div>
                              <v-chip
                                class="mb-1"
                                label
                                small
                                color="blue white--text"
                                >{{ data.serial_no }}</v-chip
                              >
                            </div>
                            <div>
                              <!-- <v-chip
                                  label
                                  :color="
                                    data.main_type == 'main_customer'
                                      ? '#4CAF50'
                                      : '#0D47A1'
                                  "
                                  class="text-white p-3 mr-5 mb-1"
                                  small
                                >
                                  <template
                                    v-if="data.main_type == 'main_customer'"
                                  >
                                    Customer
                                  </template>
                                  <template v-else> Company </template>
                                </v-chip> -->
                            </div>
                            <!-- <v-chip
                              label
                              :color="data.type == 'personal' ? 'cyan' : 'red'"
                              outlined
                              small
                              class="text-white p-3 mr-5"
                            >
                              <template v-if="data.type == 'personal'">
                                Customer/Own
                              </template>
                              <template v-else> {{ appCompanyName }} </template>
                            </v-chip> -->
                          </template>
                          <template v-else>
                            <em class="text-muted"> no serial no </em></template
                          >

                          <!--  <p class="m-0 custom-nowrap-ellipsis">
                            <b>DO No: </b>
                            <template v-if="data && data?.do_no">{{
                              data?.do_no
                            }}</template>
                            <template v-else
                              ><em class="text-muted">No do no.</em></template
                            >
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Comm Cert.: </b>
                            <template v-if="data && data?.common_cert_no">{{
                              data?.common_cert_no
                            }}</template>
                            <template v-else
                              ><em class="text-muted"
                                >No comm cert.</em
                              ></template
                            >
                          </p> -->
                        </template>
                        <template v-else-if="cols.field == 'location'">
                          <template v-if="data && data.location">{{
                            data.location
                          }}</template>
                          <em v-else class="text-muted"> no location</em>
                        </template>
                        <template v-else-if="cols.field == 'is_purchase_info'">
                          <div
                            class="warranty-listing-product"
                            v-if="data && data.is_purchase_info == 1"
                          >
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Supplier : </b>
                              <template v-if="data.supplier_display_name">
                                {{ data.supplier_display_name }}</template
                              >
                              <template v-else>
                                <em class="text-muted">
                                  no Supplier
                                </em></template
                              >
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Purchased On: </b>

                              <template v-if="data.purchased_on">
                                <v-chip
                                  outlined
                                  text-color=""
                                  label
                                  small
                                  color="green"
                                  v-if="data.purchased_on"
                                >
                                  {{ formatDate(data.purchased_on) }}
                                </v-chip>
                              </template>
                              <template v-else>
                                <em class="text-muted">
                                  no purchased date
                                </em></template
                              >
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Cost Price : </b>
                              <v-chip
                                outlined
                                text-color=""
                                label
                                small
                                color="blue"
                                v-if="data.cost_price"
                              >
                                {{ formatMoney(data.cost_price) }}
                              </v-chip>
                            </p>
                          </div>
                          <em v-else class="text-muted">
                            no purchased details</em
                          >
                        </template>
                        <template v-else-if="cols.field == 'has_warranty'">
                          <div
                            class="warranty-listing-product"
                            v-if="
                              data &&
                              data.has_warranty == 1 &&
                              data.warranry_start_date
                            "
                            v-on:click.stop.prevent="
                              detailWarrnty(data.warranry_id)
                            "
                          >
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Warranty # &nbsp; </b>
                              <span class="font-weight-700">{{
                                data?.warranry_barcode
                              }}</span>
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Start Date &nbsp; &nbsp;</b>
                              <v-chip
                                outlined
                                text-color=""
                                class="mb-1"
                                label
                                small
                                color="green"
                                v-if="data.warranry_start_date"
                              >
                                {{ formatDate(data.warranry_start_date) }}
                              </v-chip>
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>End End &nbsp;&nbsp; &nbsp; &nbsp;</b>
                              <v-chip
                                outlined
                                text-color=""
                                class="mb-1"
                                label
                                small
                                color="red"
                                v-if="data.warranry_end_date"
                              >
                                {{ formatDate(data.warranry_end_date) }}
                              </v-chip>
                            </p>

                            <p class="m-0 custom-nowrap-ellipsis">
                              <b
                                >Status &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                                &nbsp;</b
                              >
                              <v-chip
                                small
                                label
                                text-color="white"
                                :color="getStatusColor(data.warranry_status)"
                                v-if="data.warranry_status"
                              >
                                {{ getStatusText(data.warranry_status) }}
                              </v-chip>
                            </p>
                          </div>
                          <em v-else class="text-muted">
                            no warranty details</em
                          >
                        </template>

                        <template
                          v-else-if="cols.field == 'has_preventive_maintanance'"
                        >
                          <div
                            class="warranty-listing-product"
                            v-if="
                              data &&
                              data.has_preventive_maintanance == 1 &&
                              data.preventive_maintanance_start_date
                            "
                            v-on:click.stop.prevent="
                              detailMaintenance(data.preventive_maintanance_id)
                            "
                          >
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Start Date </b>
                              <v-chip
                                outlined
                                text-color=""
                                class="mb-1"
                                label
                                small
                                color="green"
                                v-if="data.preventive_maintanance_start_date"
                              >
                                {{
                                  formatDate(
                                    data.preventive_maintanance_start_date
                                  )
                                }}
                              </v-chip>
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>End End &nbsp;&nbsp; </b>
                              <v-chip
                                outlined
                                text-color=""
                                class="mb-1"
                                label
                                small
                                color="red"
                                v-if="data.preventive_maintanance_end_date"
                              >
                                {{
                                  formatDate(
                                    data.preventive_maintanance_end_date
                                  )
                                }}
                              </v-chip>
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b
                                >Cost
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </b>
                              <v-chip
                                outlined
                                class="mb-1"
                                text-color=""
                                label
                                small
                                color="blue"
                                v-if="data.preventive_maintanance_cost"
                              >
                                {{
                                  formatMoney(data.preventive_maintanance_cost)
                                }}
                              </v-chip>
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Status &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b>
                              <v-chip
                                small
                                label
                                text-color="white"
                                :color="
                                  getStatusColor(
                                    data.preventive_maintanance_status
                                  )
                                "
                                v-if="data.preventive_maintanance_status"
                              >
                                {{
                                  getStatusText(
                                    data.preventive_maintanance_status
                                  )
                                }}
                              </v-chip>
                            </p>
                          </div>
                          <em v-else class="text-muted">
                            no preventive maintenance details</em
                          >
                        </template>

                        <template v-else-if="cols.field == 'equipment_other'">
                          <div class="warranty-listing-product">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>End Life: </b>
                              <template v-if="data && data.end_life">{{
                                formatDate(data.end_life)
                              }}</template>
                              <template v-else
                                ><em class="text-muted"
                                  >No end life</em
                                ></template
                              >
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis" v-if="false">
                              <b>Contact for Item: </b>
                              <template v-if="data && data.company_number">{{
                                data.company_number
                              }}</template>
                              <template v-else
                                ><em class="text-muted"
                                  >No Contact for Item</em
                                ></template
                              >
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'brand'">
                          <div class="warranty-listing-product">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Brand: </b>
                              <template v-if="data.brand">
                                {{ data.brand }}</template
                              >
                              <template v-else>
                                <em class="text-muted"> no brand </em></template
                              >
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Category: </b>
                              <v-chip
                                outlined
                                text-color=""
                                label
                                small
                                color="purple"
                                v-if="data.category"
                              >
                                {{ data.category }}
                              </v-chip>
                              <template v-else
                                ><em class="text-muted"
                                  >No Category</em
                                ></template
                              >
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'added_at'">
                          <!-- <div v-if="hover">
                              <v-btn icon color="red lighten-1"
                                v-on:click.stop.prevent="open_delete_dailog(row)"><v-icon>mdi-delete</v-icon></v-btn>
                            </div> -->
                          <template>
                            <v-chip
                              class="mx-2 my-1 custom-grey-border"
                              color="blue-grey darken-1"
                              text-color="white"
                            >
                              <v-avatar left>
                                <v-icon>mdi-account-circle</v-icon>
                              </v-avatar>
                              <p class="m-0 custom-nowrap-ellipsis">
                                {{ data.added_user_display_name }}
                              </p>
                            </v-chip>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                  class="mx-2 my-1 custom-grey-border"
                                  color="blue-grey darken-1"
                                  text-color="white"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-avatar left>
                                    <v-icon>mdi-clock-outline</v-icon>
                                  </v-avatar>
                                  <p class="m-0 custom-nowrap-ellipsis">
                                    <template
                                      v-if="getDateCountAll(data.added_at)"
                                      >{{ data.created_at }}</template
                                    >
                                    <template v-else>{{
                                      formatDateTime(data.added_at)
                                    }}</template>
                                  </p>
                                </v-chip>
                              </template>
                              <p class="m-0 custom-nowrap-ellipsis">
                                {{ formatDateTime(data.added_at) }}
                              </p>
                            </v-tooltip>
                          </template>
                        </template>
                        <template v-else-if="cols.field == 'updated_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.updated_by)"
                            :data="data"
                          >
                            <template v-slot:display_name>
                              {{ data.updated_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              {{ data.modified_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDateTime(data.updated_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else>
                          <div v-html="printRowData(cols, data)"></div>
                        </template>
                      </td>
                    </template>
                  </tr>
                </template>
                <tr v-else-if="!dataLoading">
                  <td :colspan="11" class="py-4">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no own asset at the moment.
                    </p>
                  </td>
                </tr>
              </tbody>
            </template>
          </ListingTable>
        </perfect-scrollbar>

        <ListingFooter
          :dataLoading="dataLoading"
          :showingFrom="showingFrom"
          :showingTo="showingTo"
          :totalRows="totalRows"
          :currentPage="currentPage"
          :totalPages="totalPages"
        ></ListingFooter>

        <Dialog :commonDialog="searchDialog">
          <template v-slot:title>Filter Equipments</template>
          <template v-slot:body>
            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll custom-box-top-inner-shadow"
              style="max-height: 90vh; position: relative"
            >
              <v-container fluid class="pt-0 custom-search-filter">
                <v-row>
                  <template v-for="(field, index) in searchableArray">
                    <v-col md="6" :key="index" v-if="field != 'dates'">
                      <v-text-field
                        v-model.trim="listingSearch[field]"
                        dense
                        filled
                        hide-details
                        :label="
                          field == 'do_no'
                            ? 'DO no.'
                            : field == 'common_cert_no'
                            ? 'Common certificate no.'
                            : getFilterLabel(field, 'Customer')
                        "
                        solo
                        flat
                        clearable
                        @keydown.enter="filterRows"
                        color="cyan"
                      ></v-text-field>
                    </v-col>
                    <!-- <v-col md="6" :key="index" v-if="field != 'do_no'">
                        <v-text-field
                          v-model.trim="listingSearch[field]"
                          dense
                          filled
                          hide-details
                          label="DO No."
                          placeholder="DO no."
                          solo
                          flat
                          clearable
                          @keydown.enter="filterRows"
                          color="cyan"
                        ></v-text-field>
                      </v-col> -->
                  </template>
                </v-row>
              </v-container>
            </perfect-scrollbar>
          </template>
          <template v-slot:action>
            <div class="">
              <v-btn
                depressed
                :disabled="dataLoading"
                class="mx-2 custom-grey-border custom-bold-button"
                color="cyan white--text"
                v-on:click="filterRows"
              >
                Filter
              </v-btn>
              <v-btn
                depressed
                :disabled="dataLoading"
                class="mx-2 custom-grey-border custom-bold-button"
                v-on:click="searchDialog = false"
              >
                Close
              </v-btn>
            </div>
          </template>
        </Dialog>

        <ImportTemplate
          :dialog="importDialog"
          api-url="equipment/import"
          title="Import Equipment"
          sample-url="#"
          v-on:close="importDialog = false"
          sample-file="media/import-equipment-company.xlsx"
          v-on:successExcel="getRows()"
          :equipment-type-company="
            companyEquipmentType ? companyEquipmentType : 'tool'
          "
        ></ImportTemplate>

        <template v-if="addWarrantyDialog && getPermission('warranty:create')">
          <ManageWarranty
            :allCustomerList="allCustomerList"
            :allEquipemtList="allEquipemtList"
            :dialog-status="addWarrantyDialog"
            v-on:close:dialog="addWarrantyDialog = false"
            v-on:update:warranty="getRows"
          ></ManageWarranty>
        </template>
      </template>
    </ListingTemplate>
    <ExtentWarranty
      v-if="extentWarrantyDialog"
      :allCustomerList="allCustomerList"
      :allEquipemtList="allEquipemtList"
      :warrantyDetail="warrantyArr"
      :dialogStatus="extentWarrantyDialog"
      v-on:close:dialog="extentWarrantyDialog = false"
      v-on:update:warranty="getRows"
    ></ExtentWarranty>
  </div>
  <!--end::Customer Listing-->
</template>

<script>
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { PATCH } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";

/* import TableActivity from "@/view/pages/partials/Table-Activity.vue"; */
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ExtentWarranty from "@/view/pages/warranty/Extended-Warranty.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import PageTips from "@/view/layout/extras/offcanvas/PageTips.vue";
import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";
import ImportTemplate from "@/view/pages/partials/Import-Template.vue";
/* import JwtService from "@/core/services/jwt.service";
  import { saveAs } from "file-saver"; */
import { toSafeInteger } from "lodash";
import moment from "moment";
import showBarcode from "@/view/components/showBarcode.vue";

import ManageWarranty from "@/view/pages/partials/Create-Or-Update-Warranty.vue";
/* import CustomStatus from "@/view/pages/partials/CustomStatus.vue"; */

export default {
  mixins: [CommonMixin, ListingMixin],
  name: "equipment-list",
  data() {
    return {
      pageTips: false,
      exportLoading: false,
      pageModule: "equipment-listing",
      routeAPI: "equipment",
      momentObject: moment,
      routeName: "equipment",
      routeDetailName: "equipment.detail",
      filter_type: "main_company",
      allEquipemtList: [],
      status: "all",
      addWarrantyDialog: false,
      customerList: [],
      categoryList: [],
      propertyList: [],
      file: "",
      warrantyArr: {},
      entity: {},
      importDialog: false,
      extentWarrantyDialog: false,
      statusList: [],
      customThead: [],
      moreActions: [
        {
          title: "Import Customer(s)",
          action: "import_items",
          divider: true,
          icon: "mdi-database-import",
        },

        /*
          {
            title: "Export Customer(s)",
            action: "export_items",
            divider: false,
            icon: "mdi-database-export",
          },*/
        /*{
            title: "Export Current View",
            action: "export_current_view",
            divider: true,
            icon: "mdi-database-export",
          },*/
        {
          title: "Refresh List",
          action: "refresh_list",
          divider: true,
          icon: "mdi-refresh",
        },
      ],
      equipmentType: [
        {
          text: "Company",
          value: "main_company",
          color: "blue accent-4",
        },
        {
          text: "Customer",
          value: "main_customer",
          color: "green accent-4",
        },
      ],
      equipmentMoreAction: [
        {
          title: "Mark as Active",
          icon: "mdi-check-all",
          action: "active",
        },
        {
          title: "Mark as In-Active",
          icon: "mdi-check-all",
          action: "inactive",
        },
      ],

      companyEquipmentType: "tool",
    };
  },
  components: {
    draggable,
    PageTips,
    ImportTemplate,
    PageHeaderCount,
    Barcode,
    showBarcode,
    /* TableActivity, */
    /*  CustomStatus, */
    ManageWarranty,
    ListingTemplate,
    ListingFooter,
    ListingTable,
    ListingHeader,
    ListingSearchCriteria,
    ExtentWarranty,
    Dialog,
  },
  methods: {
    // getImportUrlLink(){
    //   if(this.companyEquipmentType=='asset'||this.companyEquipmentType=='sale'||this.companyEquipmentType=='tool'){
    //     return "media/import-equipment-company.xlsx";
    //   }
    //   return "media/import-equipment.xlsx";
    // },
    getRoute() {
      if (this.companyEquipmentType == "asset") {
        // return this.getDefaultRoute('asset-equipment.create');
        return this.getDefaultRoute("asset-equipment.create", {
          query: {
            equip_type: "asset",
          },
        });
      }
      if (this.companyEquipmentType == "sale") {
        return this.getDefaultRoute("sale-equipment.create", {
          query: {
            equip_type: "sale",
          },
        });
      }

      return this.getDefaultRoute("equipment.create");
    },
    setBread() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.getEquipTypeText(),
        },
      ]);
    },
    getEquipTypeText() {
      if (this.companyEquipmentType == "asset") {
        return "Own Assets";
      }
      if (this.companyEquipmentType == "sale") {
        return "Sale";
      }
      return "Tools";
    },
    updateMoreAction(action) {
      const _this = this;
      switch (action) {
        case "active":
          _this.updateEquipmentStatus(1);
          break;
        case "inactive":
          _this.updateEquipmentStatus(0);
          break;
      }
    },

    updateEquipmentStatus(status) {
      const _this = this;
      _this.dataLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "equipments/status",
          data: { equipment: _this.selectedRows, status },
        })
        .then(() => {
          _this.getRows();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.dataLoading = false;
        });
    },
    formatDateSartTime(row) {
      return moment(row).format("hh:mm A");
    },
    detailAsset(param) {
      this.$router.push(
        this.getDefaultRoute("asset-equipment.detail", {
          params: {
            id: param,
          },
        })
      );
    },
    detailWarrnty(param) {
      this.$router.push(
        this.getDefaultRoute("warranty.detail", {
          params: {
            id: param,
          },
        })
      );
    },
    detailMaintenance(param) {
      this.$router.push(
        this.getDefaultRoute("preventive-maintanance.detail", {
          params: {
            id: param,
          },
        })
      );
    },
    getStatusText(data) {
      if (toSafeInteger(data) == 1) {
        return "Draft";
      } else if (toSafeInteger(data) == 2) {
        return "Active";
      } else if (toSafeInteger(data) == 3) {
        return "Extended";
      } else if (toSafeInteger(data) == 4) {
        return "Terminated";
      } else if (toSafeInteger(data) == 5) {
        return "Expired";
      }
    },
    getStatusColor(data) {
      if (toSafeInteger(data) == 1) {
        return "blue";
      } else if (toSafeInteger(data) == 2) {
        return "green";
      } else if (toSafeInteger(data) == 3) {
        return "orange";
      } else if (toSafeInteger(data) == 4) {
        return "red";
      } else if (toSafeInteger(data) == 5) {
        return "teal";
      }
    },
    getStatusColorEquip(status = "") {
      let color = "cyan";
      switch (status) {
        case "Active":
          color = "green";
          break;
        case "In Active":
          color = "red";
          break;
        case "Faulty":
          color = "orange";
          break;
        case "Retired":
          color = "red";
          break;
      }
      return color;
    },
    moreAction(action) {
      const _this = this;
      switch (action) {
        case "import_items":
          _this.importDialog = true;
          break;
        case "export_items":
          _this.bulkCustomerExport();
          break;
        case "export_current_view":
          _this.exportCurrentView("customer");
          break;
        case "refresh_list":
          _this.getRows();
          break;
      }
    },
    getFormattedProperty(row) {
      let tempArr = [];
      if (row.address_unit_no) {
        tempArr.push(row.address_unit_no);
      }
      if (row.address_street_1) {
        tempArr.push(row.address_street_1);
      }
      if (row.address_street_2) {
        tempArr.push(row.address_street_2);
      }

      if (row.address_zip_code) {
        tempArr.push(row.address_zip_code);
      }
      if (row.address_country) {
        tempArr.push(row.address_country);
      }
      return tempArr.join(", ");
    },
    getFormattedCompany() {
      let tempArr = [];
      if (this.entity.unit_no) {
        tempArr.push(this.entity.unit_no);
      }
      if (this.entity.street_1) {
        tempArr.push(this.entity.street_1);
      }
      if (this.entity.street_2) {
        tempArr.push(this.entity.street_2);
      }

      if (this.entity.zip_code) {
        tempArr.push(this.entity.zip_code);
      }
      if (this.entity.landmark) {
        tempArr.push(this.entity.landmark);
      } else {
        tempArr.push("Singapore");
      }
      return tempArr.join(", ");
    },
  },
  watch: {
    "$route.query": {
      deep: true,
      immediate: true,
      handler(param) {
        if (param.equip_type) {
          if (param.equip_type == "asset") {
            this.routeDetailName = "asset-equipment.detail";
          }
          if (param.equip_type == "sale") {
            this.routeDetailName = "sale-equipment.detail";
          }
        } else {
          this.routeDetailName = "equipment.detail";
        }
        const routeN = this.$route.name;
        if (routeN == "admin.asset-equipment") {
          this.routeName = "asset-equipment";
          this.companyEquipmentType = "asset";
        } else if (routeN == "admin.sale-equipment") {
          this.routeName = "sale-equipment";
          this.companyEquipmentType = "sale";
        } else {
          this.routeName = "equipment";
          this.companyEquipmentType = "tool";
        }
        this.setBread();
      },
    },
  },
  mounted() {
    this.setBread();
    this.getRows();
  },
  beforeMount() {
    const _this = this;
    _this.lodash.assign(_this.queryParams, _this.$route.query);
    _this.status = _this.$route.query.status || _this.status;
    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 50;
  },
};
</script>
